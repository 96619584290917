import { NextLink } from "components/next-link";
import { PurpleButton } from "components/purple-button";
import { Box } from "components/ui/box";
import { Button } from "components/ui/button";
import { Container } from "components/ui/container";
import { Typography } from "components/ui/Typography";
import React from "react";
import { mainPageWidth } from "src/constants";

export const NotFound = ({
  inMainPage,
  backLink,
  title,
  description,
  backToButtonText,
}: {
  title?: string;
  description?: string;
  backToButtonText?: string;
  backLink?: string;
  inMainPage: boolean;
}) => {
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: inMainPage ? mainPageWidth : "100%",
      }}
    >
      <Box sx={{ textAlign: "center", alignItems: "center" }}>
        <Typography variant="h3" paragraph>
          {title || "Sorry, page not found!"}
        </Typography>

        <Typography sx={{ color: "text.secondary" }}>
          {description ||
            "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling."}
        </Typography>

        <PurpleButton
          href={backLink || "/"}
          component={NextLink}
          sx={{
            marginTop: "48px",
          }}
        >
          {backToButtonText || "Go Back"}
        </PurpleButton>
      </Box>
    </Container>
  );
};
